import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import moment from "moment";

const handleDownloadRoomExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchRoomData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`rooms?sortBy=name&sortOrder=ASC`)
        .then((response) => {
          if (response?.data) {
            const roomData = response?.data?.data;
            resolve(roomData);
          } else {
            reject(new Error("No data found")); // Reject if no data found
          }
        })
        .catch((error) => {
          console.error("Error fetching room data:", error);
          reject(error); // Reject with the error
        });
    });
  };

  try {
    const roomData = await fetchRoomData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Room");

    // Add headers
    worksheet.addRow([
      "Room Name",
      "Milestone",
      "Activity",
      "Status",
      "Company",
      "Location",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (roomData !== null && roomData !== undefined && roomData.length > 0) {
      roomData.forEach((room) => {
        worksheet.addRow([
          room?.name || "",
          room?.milestones?.length > 0
            ? room.milestones.map((obj) => obj.name || "--").join(", ")
            : "",
          room?.activities?.length
            ? room?.activities.map((activity) => activity.name).join(", ")
            : "",
          room?.status || "",
          room?.company_name || "",
          room?.location_name || "",
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `RoomData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadRoomExcel };
