import React, { useEffect, useState } from "react";
import { unstable_HistoryRouter, useNavigate } from "react-router-dom";
import "../Header/Header.scss";
// import profile from "../../assets/img/profile_img.svg";
import DefaultImg from "../../assets/img/DefaultImg.jpg";
import {
  Alert,
  Badge,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import { useFilter } from "../../context/FilterProvider";
import { FaArrowLeft, FaArrowRight, FaRegUser } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { Encryption } from "../../Mixin/EncryptDecryptId";
import { onMessageListener, requestPermission } from "../../firebase";
import { GoAlertFill } from "react-icons/go";

const Header = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const loggeduser = JSON.parse(localStorage.getItem("user"));
  const role_id = loggeduser?.role_id;
  const profile_photo = loggeduser?.profile_photo;
  const [companyData, setComapanyData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [total, setTotal] = useState([]);
  const [criNotificationData, setCriNotificationData] = useState([]);
  const [criTotal, setCriTotal] = useState([]);

  const {
    company_id,
    location_id,
    handleCompany,
    handleLocation,
    locationData,
    getLocationByCompany,
  } = useFilter();

  const fetchCompany = () => {
    httpService
      .get(`company/get_AllCompanies`)
      .then((response) => {
        setComapanyData(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchNotification = () => {
    httpService
      .get(`notification/get_notification`)
      .then((response) => {
        setNotificationData(response?.data?.data);
        setTotal(response?.data?.data?.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchCriticalNotification = () => {
    httpService
      .get(`notification/get_criticalNoti`)
      .then((response) => {
        setCriNotificationData(response?.data?.data);
        setCriTotal(response?.data?.data?.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  useEffect(() => {
    fetchCompany();
    if (parseInt(company_id) !== 0 && company_id !== null) {
      getLocationByCompany(company_id);
    }
    fetchNotification();
    fetchCriticalNotification();
  }, [company_id !== 0, company_id !== null]);

  const manageNotifications = (id) => {
    httpService
      .get(`notification/is_notification_viewed/${id}`)
      .then((response) => {
        if (response?.success) {
          fetchNotification();
          fetchCriticalNotification();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const clearCriNotification = (type) => {
    httpService
      .put(`notification/clear_notification/${type}`)
      .then((response) => {
        if (response?.success) {
          fetchNotification();
          fetchCriticalNotification();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleNavigateToTaskView = (id) => {
    if (id) {
      const urlEncodedId = Encryption(id);
      navigate(`/schedule/taskview/${urlEncodedId}`, {
        state: { from: "dashboard" },
      });
    }
  };

  useEffect(() => {
    requestPermission();

    const unsubscribe = onMessageListener().then((payload) => {
      fetchNotification();
      fetchCriticalNotification();
    });

    return () => {
      unsubscribe.catch((err) => console.log("failed : ", err));
    };
  }, []);
  const goBack = () => {
    navigate(-1); // Navigate back
  };

  const goForward = () => {
    navigate(1); // Navigate forward
  };
  return (
    <>
      <header className="header">
        <div className="header_inner">
          <div className="page-title p-0">
            <h3
              onClick={() => goBack()}
              className="m-0"
              style={{ paddingRight: "18px" }}
            >
              <span role="button" className="back">
                <FaArrowLeft />
              </span>
            </h3>
            <h3 onClick={() => goForward()} className="m-0">
              <span role="button" className="back">
                <FaArrowRight />
              </span>
            </h3>
          </div>
          <div className="right_part">
            <FormControl fullWidth disabled={role_id === 2 || role_id === 3}>
              <InputLabel id="demo-simple-select-label">Companies</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={company_id}
                label="Companies"
                onChange={(e) => handleCompany(e.target.value)}
              >
                <MenuItem value="" disabled selected>
                  Select Companies
                </MenuItem>
                <MenuItem value={0}>All</MenuItem>
                {companyData?.length > 0 &&
                  companyData?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.company_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth disabled={role_id === 3}>
              <InputLabel id="demo-simple-select-label">Locations</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={location_id}
                label="Locations"
                onChange={(e) => handleLocation(e.target.value)}
              >
                <MenuItem value={0}>All</MenuItem>
                {locationData &&
                  locationData?.length > 0 &&
                  locationData?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <div className="notification common_content">
              {/* <div
                className="notification_inner"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={notification} alt="notification" />
              </div> */}
              <Badge
                className="notification_inner"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                badgeContent={total}
                color="primary"
              >
                <NotificationsActiveRoundedIcon style={{ fill: "#00A272" }} />
              </Badge>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <Button
                  variant="text"
                  onClick={() => clearCriNotification(null)}
                  size="small"
                >
                  Clear All
                </Button>
                {notificationData?.length > 0 &&
                  notificationData?.map((item) => {
                    if (item?.view_status === 0) {
                      return (
                        <Alert
                          key={item?.id}
                          icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                          severity={
                            item?.type === "critical_error"
                              ? "error"
                              : "success"
                          }
                          onClick={() =>
                            handleNavigateToTaskView(
                              parseInt(item?.reference_id)
                            )
                          }
                          onClose={() => {
                            manageNotifications(item?.id);
                          }}
                          style={{ marginBottom: "10px" }}
                        >
                          {item?.message}
                        </Alert>
                      );
                    }
                  })}
              </ul>
            </div>
            <div className="notification common_content">
              <Badge
                className="notification_inner"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                badgeContent={criTotal}
                color="primary"
              >
                <GoAlertFill style={{ fontSize: "23px", fill: "#dc3545" }} />
              </Badge>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <Button
                  variant="text"
                  onClick={() => clearCriNotification("critical_error")}
                  size="small"
                >
                  Clear All
                </Button>
                {criNotificationData?.length > 0 &&
                  criNotificationData?.map((item) => {
                    if (item?.view_status === 0) {
                      return (
                        <Alert
                          key={item?.id}
                          icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                          severity={
                            item?.type === "critical_error"
                              ? "error"
                              : "success"
                          }
                          onClick={() =>
                            handleNavigateToTaskView(
                              parseInt(item?.reference_id)
                            )
                          }
                          onClose={() => {
                            manageNotifications(item?.id);
                          }}
                          style={{ marginBottom: "10px" }}
                        >
                          {item?.message}
                        </Alert>
                      );
                    }
                  })}
              </ul>
            </div>

            <div className="user common_content">
              <div
                className="user_inner"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="profile_img">
                  <img
                    src={profile_photo ? profile_photo : DefaultImg}
                    className="profile-img"
                    alt="profile"
                  />
                </div>
                <div className="dropdown-toggle user_wrapper">
                  <div className="user_name d-none d-lg-block">
                    <div className="d-flex flex-column me-3">
                      <span>{loggeduser?.user_name}</span>
                      <span className="designation">
                        {loggeduser?.role_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ul
                className="profile dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li onClick={() => handleProfile()}>
                  <a className="dropdown-item">
                    <span className="item_text">
                      <FaRegUser />
                      Profile
                    </span>
                  </a>
                </li>
                <li onClick={() => handleLogout()}>
                  <a className="dropdown-item">
                    <span className="item_text">
                      <IoIosLogOut />
                      Logout
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
