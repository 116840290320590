import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { HttpService } from "../../service/HttpService";
import { displayDuration } from "../../Mixin/DisplayDuration";
import moment from "moment";

const handleDownloadTaskExcel = async (setShowLoginPopup) => {
  const httpService = new HttpService(setShowLoginPopup);

  const fetchTaskData = () => {
    return new Promise((resolve, reject) => {
      httpService
        .get(`work-task/get_worktasks?sortBy=name&sortOrder=ASC`) // Update the endpoint if necessary
        .then((response) => {
          if (response?.data) {
            const taskData = response?.data?.data;
            resolve(taskData);
          } else {
            reject(new Error("No data found"));
          }
        })
        .catch((error) => {
          console.error("Error fetching task data:", error);
          reject(error);
        });
    });
  };

  try {
    const taskData = await fetchTaskData();

    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Work Task");

    // Add headers
    worksheet.addRow([
      "Work Task Name",
      "Duration",
      "Activity",
      "Milestone",
      "Company",
      "Location",
    ]);
    worksheet.getRow(1).alignment = { horizontal: "center" }; // Center align the text

    if (taskData && taskData.length > 0) {
      taskData.forEach((task) => {
        // Debugging: Log task object for reference
        // Ensure the task name is correctly accessed
        const taskName = task?.name || "";

        // Ensure duration is calculated correctly
        const taskDuration = displayDuration(
          task?.days,
          task?.hours,
          task?.minutes
        );

        // Handle other fields
        const activityName = task?.activity_name || "";
        const milestoneName = task?.milestone_name || "";
        const companyName = task?.company_name || "";
        const locationName = task?.location_name || "";

        // Add row to worksheet
        worksheet.addRow([
          taskName,
          taskDuration || "",
          activityName,
          milestoneName,
          companyName,
          locationName,
        ]);
      });
    }

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    const formattedDate = moment().format("MM-DD-YYYY_hh-mm-ss_A");

    // Save the Excel file
    saveAs(new Blob([buffer]), `WorkTaskData_${formattedDate}.xlsx`);
  } catch (error) {
    console.error("Error downloading Excel:", error);
  }
};

export { handleDownloadTaskExcel };
