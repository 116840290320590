import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { PiUploadSimpleThin } from "react-icons/pi";
import Button from "@mui/material/Button";
import "../View/View.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";

import FileInput from "../../FileInput/FileInput";
import { useAuth } from "../../../context/AuthProvider";
import CommonDropdown from "../../SelectInput/CommonDropdown";
import {
  Select,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  FormControl,
} from "@mui/material";

function AddEditPlant({
  open,
  isAdd,
  handleClose,
  rows,
  fetchPlant,
  companyId,
  locationId,
}) {
  const [name, setName] = useState(rows?.name);
  const [days, setDay] = useState(rows?.days);
  const [hours, setHour] = useState(rows?.hours);
  const [minutes, setMinute] = useState(rows?.minutes);
  const [description, setDescription] = useState(rows?.description);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [mileStoneData, setMileStoneData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [apiError, setApiError] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const autoSequenceOrder = async (payload, setFieldValue) => {
    return await httpService
      .post("plant/check-auto-sequence", payload)
      .then((response) => {
        if (response?.data?.max_sort_order) {
          setFieldValue("sort_order", response?.data?.max_sort_order);
          setSortOrder(response?.data?.max_sort_order);
        } else {
          setFieldValue("sort_order", "");
          setSortOrder("");
        }
        return response?.data?.max_sort_order;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        return error;
      });
  };

  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const resetForm = () => {
    setName("");
    setDay("");
    setHour("");
    setMinute("");
    setDescription("");
  };

  const fetchCompanies = () => {
    httpService
      .get("company/get_companies")
      .then((response) => {
        setCompanies(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const getLocationByCompany = (id) => {
    if (id) {
      return httpService
        .get(`location/get_location/company/${id}`)
        .then((res) => {
          setLocations(res?.data);
          return res;
        })
        .catch((error) => {
          console.error("Error fetching location data:", error);
        });
    }
  };

  const getMilestoneByLocation = async (id) => {
    try {
      const res = await httpService.get(
        `milestone/location/get_milestones/${id}`
      );
      setMileStoneData(res?.data);
      return res;
    } catch (error) {
      console.error("Error fetching milestone data:", error);
    }
  };

  const getActivityByMilestone = async (id) => {
    try {
      const res = await httpService.post(`activity/get_activity/milestone`, {
        milestone_id: id,
      });
      setActivityData(res);
    } catch (error) {
      console.error("Error fetching activity data:", error);
    }
  };

  const handleChangeLocation = (key, id) => {
    if (key === "company_id") {
      setLocations("");
      setMileStoneData([]);
      setActivityData([]);
      getLocationByCompany(id);
    }
    if (key === "location_id") {
      setMileStoneData([]);
      setActivityData([]);
      getMilestoneByLocation(id);
    }
    if (key === "milestone_id") {
      getActivityByMilestone(id);
      setActivityData([]);
      setRooms([]);
    }
    if (key === "activity_id") {
      setRooms([]);
      getRoomByActivity(id);
    }
  };

  const getRoomByActivity = (id) => {
    httpService
      .post("rooms/get_room/activity", {
        activity_id: id,
      })
      .then((res) => {
        setRooms(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const matchSequenceOrder = async (payload) => {
    if (sortOrder === "") {
      return await httpService
        .post("plant/check-sequence", payload)
        .then((response) => {
          setApiError("");
          return response;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setApiError(error?.message);
          return error;
        });
    }
  };

  useEffect(() => {
    fetchCompanies();
    if (companyId > 0) {
      getLocationByCompany(companyId);
    }
    if (locationId > 0) {
      getMilestoneByLocation(locationId);
    }
  }, [companyId, locationId, showLoginPopup]);

  useEffect(() => {
    if (rows?.company_id) {
      getLocationByCompany(rows?.company_id);
    }
    if (rows?.location_id) {
      getMilestoneByLocation(rows?.location_id);
    }
    if (rows?.milestones?.length > 0) {
      getActivityByMilestone(rows?.milestones.map((item) => item.id));
    }
    if (rows?.activities?.length > 0) {
      getRoomByActivity(rows?.activities.map((item) => item.id));
    }
  }, [rows]);

  return (
    <Modal
      className="plant-modal"
      open={open}
      onClose={() => {
        handleClose();
        resetForm();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Formik
        initialValues={{
          name: name || "",
          days: days || "",
          hours: hours || "",
          minutes: minutes || "",
          description: description || "",
          plant_profile_img: "",
          file: [],
          attachment: rows?.attachment || [],
          sort_order: rows?.sort_order || "",
          version: rows?.version || "",
          location_id:
            (locationId > 0 && locationId) || rows?.location_id || "",
          company_id: (companyId > 0 && companyId) || rows?.company_id || "",
          milestone_id:
            (rows?.milestones?.length > 0 &&
              rows?.milestones.map((item) => item.id)) ||
            [],
          activity_id:
            (rows?.activities?.length > 0 &&
              rows?.activities.map((item) => item.id)) ||
            [],
          room_id:
            (rows?.rooms?.length > 0 && rows?.rooms.map((item) => item.id)) ||
            [],
        }}
        onSubmit={async (values) => {
          if (!apiError) {
            var form_data = new FormData();
            // console.log("form_data", form_data);
            if (isAdd == true) {
              for (var key in values) {
                if (key === "file") {
                  continue; // Skip appending the 'file' key
                } else if (key === "sort_order") {
                  form_data.append(key, values[key] || "");
                } else if (key === "attachment") {
                  for (let index = 0; index < values[key].length; index++) {
                    form_data.append(key`[${index}]`, values[key][index]);
                  }
                } else if (key === "activity_id") {
                  values?.activity_id?.forEach((item, index) => {
                    form_data.append(`activity_id[${index}]`, item);
                  });
                } else if (key === "milestone_id") {
                  values?.milestone_id?.forEach((item, index) => {
                    form_data.append(`milestone_id[${index}]`, item);
                  });
                } else if (key === "room_id" && values?.room_id.length > 0) {
                  values?.room_id?.forEach((item, index) => {
                    form_data.append(`room_id[${index}]`, item || "");
                  });
                } else {
                  form_data.append(key, values[key]);
                }
              }
              for (let index = 0; index < values.file.length; index++) {
                form_data.append("attachment", values.file[index]);
              }
              try {
                const response = await httpService.post(
                  `${"plant/add_plant"}`,
                  form_data
                );
                // console.log("response", response);
                if (response) {
                  // alert("hi")
                  toast.success("Plant Added Successfully", {
                    position: "top-right",
                  });
                  handleClose();
                  //setFile([]);
                  fetchPlant();
                } else {
                  toast.error("Something went wrong.", {
                    position: "top-right",
                  });
                }
              } catch (error) {
                toast.error(`${error.message}`, {
                  position: "top-right",
                });
              }
            } else {
              // values.existFile = file;

              for (var key in values) {
                if (key === "plant_profile_img" && !values[key]) {
                  continue;
                } else if (key === "sort_order") {
                  form_data.append(key, values[key] || "");
                } else if (key === "attachment") {
                  for (let index = 0; index < values[key].length; index++) {
                    form_data.append(`existFile[${index}]`, values[key][index]);
                  }
                } else if (key === "file") {
                  for (let index = 0; index < values[key].length; index++) {
                    form_data.append(`attachment`, values[key][index]);
                  }
                } else if (key === "activity_id") {
                  values?.activity_id?.forEach((item, index) => {
                    form_data.append(`activity_id[${index}]`, item);
                  });
                } else if (key === "milestone_id") {
                  values?.milestone_id?.forEach((item, index) => {
                    form_data.append(`milestone_id[${index}]`, item);
                  });
                } else if (key === "plant_profile_img" && values[key]) {
                  form_data.append(`plant_profile_img`, values[key]);
                } else if (key === "room_id" && values?.room_id.length > 0) {
                  values?.room_id?.forEach((item, index) => {
                    form_data.append(`room_id[${index}]`, item || "");
                  });
                } else {
                  form_data.append(key, values[key]);
                }
              }
              try {
                const response = await httpService.put(
                  `${`plant/edit_plant/${rows?.id}`}`,
                  form_data
                );

                toast.success("Plant Updated Successfully", {
                  position: "top-right",
                });
                handleClose();
                //setFile([]);
                fetchPlant();
              } catch (error) {
                toast.error(`${error?.response?.data?.error}`, {
                  position: "top-right",
                });
              }
            }
          }
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().required("Please Enter Name"),
            days: Yup.number().nullable(),
            hours: Yup.number().nullable().max(24, "Maximum 24 hours"),
            minutes: Yup.number().nullable().max(60, "Maximum 60 minutes"),
            company_id: Yup.string().required("Please Select Company"),
            location_id: Yup.string().required("Please Select Location"),
            // activity_id: Yup.array()
            //   .min(1, "Please Select Activity")
            //   .required("Please Select Activity"),
            // milestone_id: Yup.array()
            //   .min(1, "Please Select Milestone")
            //   .required("Please Select Milestone"),
            plant_profile_img: Yup.mixed()
              // .required("Profile Image Is Required")
              .test(
                "fileSize",
                "Profile image size must be less than 10MB",
                (value) => {
                  return !value || (value && value.size <= 10485760); // 10MB in bytes
                }
              )
              .test(
                "fileFormat",
                "Profile image must be in jpg, png, jpeg, gif, or webp format",
                (value) => {
                  return (
                    !value ||
                    (value &&
                      [
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                        "image/gif",
                        "image/webp",
                      ].includes(value.type))
                  );
                }
              ),
            // description: Yup.string().required("Please Enter Description"),
            file: Yup.array()
              // .required("Attachment Is Required")
              .test(
                "fileSize",
                "Attachment size must be less than 10MB",
                (value) => {
                  if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
                  const files = Object.values(value); // Convert object values to an array of files
                  return files.every((file) => file.size <= 10485760); // Check size of each file
                }
              )
              .test("fileFormat", "Invalid file format", (value) => {
                if (!value || typeof value !== "object") return true; // Skip if no files provided or value is not an object
                const files = Object.values(value); // Convert object values to an array of files
                if (files.length === 0) return true; // Skip if no files provided
                const allowedExtensions = {
                  video: ["mp4", "webm", "mov"],
                  pdf: ["pdf"],
                  document: ["doc", "docx"],
                  spreadsheet: ["xls", "xlsx", "csv"],
                  image: ["jpg", "png", "jpeg", "gif", "webp"],
                };
                return files.every((file) => {
                  if (!file || !file.name) return false; // If file or file name is undefined, return false
                  const fileExtension = file?.name
                    ?.split(".")
                    .pop()
                    .toLowerCase();
                  const fileType = Object.keys(allowedExtensions).find((type) =>
                    allowedExtensions[type].includes(fileExtension)
                  );
                  return !!fileType;
                });
              }),
          })
          //   .test("at-least-one", null, (values) => {
          //     const { days, hours, minutes } = values;
          //     if (!days && !hours && !minutes) {
          //       return new Yup.ValidationError(
          //         "Please Enter One Duration",
          //         null,
          //         "days"
          //       );
          //     }
          //     if (days < 0 || hours < 0 || minutes < 0) {
          //       return new Yup.ValidationError(
          //         "Please Enter Valid Duration",
          //         null,
          //         "days"
          //       );
          //     }
          //     return true;
          //   }
          // )
        }
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            resetForm,
          } = props;

          if (
            values?.sort_order === "" &&
            companyId > 0 &&
            locationId > 0 &&
            isAdd === true
          ) {
            autoSequenceOrder(
              { company_id: companyId, location_id: locationId },
              setFieldValue
            );
          }
          return (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="modal_block">
                <div className="modal_header">
                  <div className="modal_title_wrapper">
                    <h2 className="modal_title">
                      {isAdd === true ? "Add Plant" : "Edit Plant"}
                    </h2>
                  </div>
                  <div className="cancel-btn-wrap">
                    <RxCross1
                      onClick={() => {
                        handleClose();
                        resetForm();
                      }}
                    />
                  </div>
                </div>
                <div className="modal_content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>
                          Name<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextField
                          id="name"
                          name="name"
                          InputProps={{}}
                          placeholder="Enter Your Plant Name"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <span style={{ color: "red" }}>{errors.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form_field_wrapper">
                        <label>
                          Duration
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <TextField
                          type="number"
                          id="days"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="DD"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.days}
                          // value={days}
                        />
                        {errors.days && touched.days && (
                          <span style={{ color: "red" }}>{errors.days}</span>
                        )}
                        {errors.hours && touched.hours && (
                          <span style={{ color: "red" }}>{errors.hours}</span>
                        )}
                        {errors.minutes && touched.minutes && (
                          <span style={{ color: "red" }}>{errors.minutes}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form_field_wrapper">
                        <label>&nbsp;</label>
                        <TextField
                          type="number"
                          id="hours"
                          name="hours"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="HH"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.hours}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form_field_wrapper">
                        <label>&nbsp;</label>
                        <TextField
                          type="number"
                          id="minutes"
                          name="minutes"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="MM"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.minutes}
                        />
                      </div>
                    </div>

                    <CommonDropdown
                      companyId={companyId}
                      locationId={locationId}
                      valuesCompany={values?.company_id}
                      valuesLocation={values?.location_id}
                      valuesActivity={values?.activity_id}
                      valuesMilestone={values?.milestone_id}
                      com_id={"company_id"}
                      loc_id={"location_id"}
                      act_id={"activity_id"}
                      mil_id={"milestone_id"}
                      sort_order={values?.sort_order}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      companies={companies}
                      locations={locations}
                      milestones={mileStoneData}
                      activities={activityData}
                      errorCompany={touched.company_id && errors.company_id}
                      errorLocation={touched.location_id && errors.location_id}
                      errorMilestone={
                        touched.milestone_id && errors.milestone_id
                      }
                      errorActivity={touched.activity_id && errors.activity_id}
                      handleChangeLocation={(key, id) => {
                        handleChangeLocation(key, id);
                        if (key === "company_id") {
                          setFieldValue("location_id", []);
                          setFieldValue("milestone_id", []);
                          setFieldValue("activity_id", []);
                          setFieldValue("room_id", []);
                        }
                        if (key === "location_id") {
                          setFieldValue("milestone_id", []);
                          setFieldValue("activity_id", []);
                          setFieldValue("room_id", []);
                        }
                        if (key === "milestone_id") {
                          setFieldValue("activity_id", []);
                          setFieldValue("room_id", []);
                        }
                        if (key === "activity_id") {
                          setFieldValue("room_id", []);
                        }
                      }}
                      rows={rows?.id}
                      setApiError={setApiError}
                      matchSequenceOrder={matchSequenceOrder}
                      autoSequenceOrder={autoSequenceOrder}
                      valuesEmployee={[1]}
                    />
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>Room Name</label>
                        <div className="fieldwithradio">
                          <FormControl key={"room"} fullWidth>
                            <Select
                              id="demo-simple-select-room"
                              name="room_id"
                              type="number"
                              value={values.room_id}
                              multiple
                              onChange={(event) => {
                                const value = event?.target?.value;
                                if (value[value.length - 1] === "all") {
                                  const selectedIds =
                                    values.room_id?.length === rooms.length
                                      ? []
                                      : rooms.map((item) => item.id);
                                  setFieldValue("room_id", selectedIds);
                                  return;
                                }
                                setFieldValue("room_id", value);
                              }}
                              onBlur={handleBlur}
                              displayEmpty
                              MenuProps={{
                                getcontentanchorel: null,
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                keepMounted: true,
                              }}
                              renderValue={(selected) => {
                                if (selected?.length === 0) {
                                  return <span>Select Rooms</span>;
                                }
                                const selectedData =
                                  selected &&
                                  selected?.map(
                                    (id) =>
                                      rooms.find((item) => item.id === id)
                                        ?.prefix_name ||
                                      rooms.find((item) => item.id === id)?.name
                                  );

                                return selectedData?.join(", ");
                              }}
                              className="w-100"
                            >
                              <MenuItem value="" selected disabled>
                                Select Room
                              </MenuItem>
                              {rooms && rooms?.length > 0 && (
                                <MenuItem value="all">
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        values?.room_id?.length ===
                                        rooms?.length
                                      }
                                      indeterminate={
                                        values?.room_id?.length > 0 &&
                                        values?.room_id?.length < rooms?.length
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary="Select All" />
                                </MenuItem>
                              )}
                              {rooms?.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  <Checkbox
                                    checked={
                                      values?.room_id?.length > 0 &&
                                      values?.room_id?.includes(item.id)
                                    }
                                  />
                                  <ListItemText
                                    primary={item.prefix_name || item.name}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        {touched.room_id && errors.room_id ? (
                          <div style={{ color: "red" }}>{errors.room_id}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>Sequence(#)</label>
                        <TextField
                          name="sort_order"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="Enter Sequence Number"
                          value={values.sort_order}
                          onChange={async (e) => {
                            setFieldValue("sort_order", e.target.value);
                            if (
                              values?.company_id &&
                              values?.location_id &&
                              e.target.value
                            ) {
                              const payload = {
                                company_id: values?.company_id,
                                location_id: values?.location_id,
                                sort_order: e.target.value,
                                id: rows?.id,
                              };
                              matchSequenceOrder(payload);
                            } else {
                              setApiError("");
                            }
                          }}
                          onBlur={handleBlur}
                        />
                        {apiError && (
                          <span style={{ color: "red" }}>{apiError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_field_wrapper">
                        <label>
                          Reff Doc. Version(s)
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <TextField
                          name="version"
                          type="text"
                          InputProps={{ inputProps: { min: 0 } }}
                          placeholder="Enter Version"
                          value={values.version}
                          onChange={async (e) => {
                            setFieldValue("version", e.target.value);
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.version && touched.version && (
                          <span style={{ color: "red" }}>{errors.version}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form_field_wrapper">
                        <label>Description</label>
                        <div>
                          <TextareaAutosize
                            id="description"
                            name="description"
                            className="w-100 text-area"
                            aria-label="Type Description"
                            minRows={3}
                            placeholder="Type Description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          {errors.description && touched.description && (
                            <span style={{ color: "red" }}>
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form_field_wrapper">
                        <label>Profile Image</label>
                        <div className="upload-btn-wrap">
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<PiUploadSimpleThin />}
                          >
                            <input
                              className="file-type"
                              type="file"
                              accept="image/*"
                              name="plant_profile_img"
                              onChange={(e) => {
                                setFieldValue(
                                  "plant_profile_img",
                                  e.currentTarget.files[0]
                                );
                              }}
                              handleBlur={handleBlur}
                            />
                            {values.plant_profile_img ? (
                              <span>{values.plant_profile_img?.name}</span> // Displaying the name of the image
                            ) : (
                              <span>Upload Profile Image</span>
                            )}
                          </Button>
                        </div>
                        {errors.plant_profile_img &&
                          touched.plant_profile_img && (
                            <span style={{ color: "red" }}>
                              {errors.plant_profile_img}
                            </span>
                          )}
                      </div>
                    </div>

                    <FileInput
                      file={values.file}
                      attachment={values.attachment}
                      handleBlur={handleBlur}
                      name="file"
                      name2="attachment"
                      setFieldValue={(fieldName, value) =>
                        setFieldValue(fieldName, value)
                      }
                    />
                    {errors.file && touched.file && (
                      <span style={{ color: "red" }}>{errors.file}</span>
                    )}
                  </div>
                </div>
                <div className="modal_footer">
                  <button
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                    className="btn btn-border"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {isAdd === true ? "Save" : "Update"}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddEditPlant;
