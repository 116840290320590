import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption, Encryption } from "../../../Mixin/EncryptDecryptId";
import AddEditSchedule from "../AddForm/AddEditSchedule";
import dayjs from "dayjs";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import {
  IconButton,
  Popover,
  MenuItem,
  Badge,
  styled,
  Chip,
} from "@mui/material";
import { HiOutlineEye } from "react-icons/hi2";
import DownloadButton from "../../DownloadButton";

const CustomBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#ffae00",
    color: "white",
  },
}));

const SopsView = () => {
  const params = useParams();
  const { id } = params || {};
  const [schedule, setSchedule] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [DeleteSchedule, setDeleteSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const decryptedId = Decryption(id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [searchData, setSearchData] = useState([]);
  const location = useLocation();

  const fetchSchedule = () => {
    setLoading(true);
    try {
      httpService
        .get(`schedule/get_schedule/${decryptedId}`)
        .then((response) => {
          setSchedule(response?.data);
          setSearchData(response?.data?.schedules);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSchedule();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpen(true);
    fetchSchedule();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteSchedule(false);
    fetchSchedule();
  };

  const HandleDeleteSchedule = async () => {
    try {
      let response = await httpService.delete(
        `${"schedule/delete_schedule"}/${decryptedId}`
      );
      navigate("/schedule");
      if (response?.success === true) {
        toast.success("Schedule Deleted successfully", {
          position: "top-right",
        });
        setDeleteSchedule(false);
      }
    } catch (error) {
      setDeleteSchedule(false);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const viewPage = (id, task_id, type) => {
    const urlEncodedId = Encryption(id);
    const urlEncodeTaskId = Encryption(task_id);
    navigate(`/schedule/taskview/${urlEncodedId}`, {
      state: {
        prev_id: decryptedId,
        from: location.state?.from,
        task_id: urlEncodeTaskId,
        task_type: type
      },
    });
  };

  // const searchSchedules = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   console.log("ttt -=-=",searchTermLowerCase,schedule)
  //   const results =
  //   schedule?.schedules?.length > 0 &&
  //   schedule?.schedules?.filter((item) => {
  //     for (let key in item) {
  //       if (
  //         typeof item[key] === "string" &&
  //         item[key].toLowerCase().includes(searchTermLowerCase)
  //       ) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };
  //   const searchSchedules = (searchTerm) => {
  //     const searchTermLowerCase = searchTerm.toLowerCase();
  //     console.log("Searching for term:", searchTermLowerCase, schedule);

  //     const results = schedule?.schedules?.length > 0 &&
  //         schedule.schedules.filter((item) => {
  //             return item?.milestone_name?.toLowerCase()?.includes(searchTermLowerCase) ||
  //                    item?.activity_name?.toLowerCase()?.includes(searchTermLowerCase) ||
  //                    item?.task_name?.toLowerCase()?.includes(searchTermLowerCase)||
  //                    item?.start_date?.toLowerCase()?.includes(searchTermLowerCase)||
  //                    item?.end_date?.toLowerCase()?.includes(searchTermLowerCase);

  //         });

  //     if (results) {
  //         setSearchData(results);
  //     }
  // };
  const searchSchedules = (searchTerm) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    // console.log("Searching for term:", searchTermLowerCase, schedule);

    const results =
      schedule?.schedules?.length > 0 &&
      schedule.schedules.filter((item) => {
        const matchesMilestone = item?.milestone_name
          .toLowerCase()
          .includes(searchTermLowerCase);
        const matchesActivity = item?.activity_name
          .toLowerCase()
          .includes(searchTermLowerCase);
        const matchesTask = item?.task_name
          .toLowerCase()
          .includes(searchTermLowerCase);
        const matchstartdate = item?.start_date
          ?.toLowerCase()
          ?.includes(searchTermLowerCase);
        const matchenddate = item?.end_date
          ?.toLowerCase()
          ?.includes(searchTermLowerCase);
        const matchesEmployee = item?.selected_employees?.some((employee) =>
          employee.name.toLowerCase().includes(searchTermLowerCase)
        );
        return (
          matchesMilestone ||
          matchesActivity ||
          matchesTask ||
          matchesEmployee ||
          matchstartdate ||
          matchenddate
        );
      });

    if (results) {
      setSearchData(results);
    }
  };

  const handleBackClick = () => {
    if (location.state?.from && location.state?.from !== "scheduleModule") {
      navigate(`/${location.state?.from}`);
    } else {
      navigate("/schedule"); // Default to task module if origin is unknown
    }
  };
  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="maintenance-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => handleBackClick()} />
              </span>
              Schedule Details - [{schedule?.name ? schedule?.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => handleOpen()}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                setDeleteSchedule(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            {/* <div className="col-md-2">
              <div>
                <img src={Milestone} alt="Maintenance img" />
              </div>
            </div> */}
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Schedule Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{schedule?.name ? schedule?.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Schedule Option </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {schedule?.type ? (schedule?.type).toUpperCase() : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Start Date</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {schedule?.adjusted_start_date
                      ? dayjs(schedule?.adjusted_start_date).format(
                          "MMM/DD/YYYY hh:mm A"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>End Date</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>
                    {schedule?.adjusted_end_date
                      ? dayjs(schedule?.adjusted_end_date).format(
                          "MMM/DD/YYYY hh:mm A"
                        )
                      : "--"}
                  </p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Plant Name</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span>
                      {schedule?.plant_name ? schedule?.plant_name : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Room Name</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span>
                      {schedule?.room_name ? schedule?.room_name : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>On-Going</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <p>
                      {schedule?.ongoing ? "On-Going" : "--"}
                  </p>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Schedules</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="table-filter-wrap">
                    <DownloadButton id={decryptedId} />
                    <div className="header_search-wrap">
                      <div className="header_search_bar common_content">
                        <div className="input-group input_group">
                          <button className="btn search-btn search_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 21 21"
                              fill="none"
                            >
                              <circle
                                cx="9.98856"
                                cy="9.98856"
                                r="8.98856"
                                stroke="#495057"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16.2402 16.7071L19.7643 20.222"
                                stroke="#495057"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <input
                            type="search"
                            className="form-control form_control"
                            placeholder="Search..."
                            aria-label="Search"
                            onChange={(e) => searchSchedules(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  style={{
                    height: `${
                      schedule?.schedules && schedule?.schedules?.length > 0
                        ? ""
                        : "200px"
                    }`,
                    width: "100%",
                  }}
                >
                  <DataGrid
                    rows={searchData ? searchData : []}
                    getRowId={(schedule) => schedule?.id} // Specify the custom row ID
                    getRowHeight={() => "auto"}
                    getRowClassName={(params) =>
                      params?.row?.conflicted_status ? "highlight" : ""
                    }
                    columns={[
                      {
                        field: "action",
                        headerName: "Action",
                        headerAlign: "center",
                        width: 80,
                        align: "center",
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => (
                          <>
                            <div className="action_icon-weap">
                              <IconButton
                                onClick={(e) => {
                                  handleMenuClick(e);
                                  setDataId(params?.row?.id);
                                }}
                              >
                                <GridMoreVertIcon />
                              </IconButton>
                            </div>
                            {anchorEl && dataId === params?.row?.id && (
                              <Popover
                                className="popup-menu"
                                key={dataId}
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    viewPage(dataId, params?.row?.task_id, params?.row?.task_type);
                                  }}
                                  className="menu-list-items"
                                >
                                  <div className="menu-list-items-text">
                                    View
                                  </div>
                                  <HiOutlineEye style={{ cursor: "pointer" }} />
                                </MenuItem>
                              </Popover>
                            )}
                          </>
                        ),
                      },  
                      {
                        field: "milestone_name",
                        headerName: "Milestone Name",
                        minWidth: 180,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.milestone_name
                                ? params.row.milestone_name
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "activity_name",
                        headerName: "Activity Name",
                        minWidth: 180,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.activity_name
                                ? params.row.activity_name
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "task_name",
                        headerName: "Task Name",
                        minWidth: 180,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.task_name
                                ? params.row.task_name
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "employees",
                        headerName: "Employees",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          const selectedEmployeeIds =
                            params.row.selected_employees;
                          const employees = params.row.employees || [];

                          // Filter employees whose IDs are in selectedEmployeeIds
                          // const selectedEmployees = employees.filter(
                          //   (employee) =>
                          //     selectedEmployeeIds.includes(employee.id)
                          // );
                          const selectedEmployees = employees.filter((item) => {
                            return selectedEmployeeIds?.some(
                              (selected) => selected.id === item.id
                            );
                          });

                          // Extract the names of the selected employees
                          const employeeNames = selectedEmployees.map(
                            (employee) => employee.name
                          );

                          return (
                            <div>
                              {employeeNames.length > 0
                                ? employeeNames.join(", ")
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "ongoing",
                        headerName: "On-Going Task",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.ongoing ? (
                                <Chip
                                  label={`On-Going`}
                                  color="primary"
                                  variant="outlined"
                                />
                              ) : (
                                "--"
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        field: "job_completed",
                        headerName: "Status",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          const jobCompleted = params.row.job_completed; // Assuming 'job_completed' is the field name

                          let status;
                          switch (jobCompleted) {
                            case 0:
                              switch (params?.row?.status) {
                                case 4:
                                  status = "To Do";
                                  break;
                                case 5:
                                  status = "Pending";
                                  break;
                                case 6:
                                  status = "Ongoing";
                                  break;
                              }
                              break;
                            case 1:
                              status = "Done";
                              break;
                            case 2:
                              status = "Approved";
                              break;
                            case 3:
                              status = "Rejected";
                              break;
                            default:
                              status = "Unknown";
                          }
                          // }

                          let statusBadge;
                          switch (status) {
                            case "Ongoing":
                              statusBadge = (
                                <CustomBadge
                                  badgeContent={"Ongoing"}
                                ></CustomBadge>
                              );
                              break;
                            case "To Do":
                              statusBadge = (
                                <Badge badgeContent={"To Do"} color="primary" />
                              );
                              break;
                            case "Pending":
                              statusBadge = (
                                <Badge
                                  badgeContent={"Pending"}
                                  color="secondary"
                                />
                              );
                              break;
                            case "Done":
                              statusBadge = (
                                <Badge badgeContent={"Done"} color="success" />
                              );
                              break;
                            case "Approved":
                              statusBadge = (
                                <Badge
                                  badgeContent={"Approved"}
                                  color="success"
                                />
                              );
                              break;
                            case "Rejected":
                              statusBadge = (
                                <Badge
                                  badgeContent={"Rejected"}
                                  color="error"
                                />
                              );
                              break;
                            default:
                              statusBadge = (
                                <Badge badgeContent={"Unknown"} color="error" />
                              );
                          }
                          return <div>{statusBadge}</div>;
                        },
                      },
                      {
                        field: "start_date",
                        headerName: "Start Date",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 180,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {dayjs(params.row.start_date).format(
                                "MMM/DD/YYYY h:mm A"
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        field: "end_date",
                        headerName: "End Date",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 180,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {dayjs(params.row.end_date).format(
                                "MMM/DD/YYYY h:mm A"
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        field: "cron_duration",
                        headerName: "Duration",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.cron_duration
                                ? params.row.cron_duration
                                    .charAt(0)
                                    .toUpperCase() +
                                  params.row.cron_duration.slice(1)
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "cron_days",
                        headerName: "Week Days",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.cron_days?.length
                                ? params.row.cron_days
                                    .map(
                                      (day) =>
                                        day.charAt(0).toUpperCase() +
                                        day.slice(1)
                                    )
                                    .join(", ")
                                : "--"}
                            </div>
                          );
                        },
                      },
                      {
                        field: "cron_frequency",
                        headerName: "Frequency",
                        headerAlign: "center",
                        align: "center",
                        minWidth: 150,
                        flex: 1,
                        sortable: false,
                        disbableColumnMenu: true,
                        renderCell: (params) => {
                          return (
                            <div>
                              {params.row.cron_frequency
                                ? params.row.cron_frequency
                                : "--"}
                            </div>
                          );
                        },
                      },
                      // {
                      //   field: "cron_start_date",
                      //   headerName: "Cron Start Date",
                      //   headerAlign: "center",
                      //   align: "center",
                      //   minWidth: 180,
                      //   flex: 1,
                      //   sortable: false,
                      //   disbableColumnMenu: true,
                      //   renderCell: (params) => {
                      //     return (
                      //       <div>
                      //         {dayjs(params.row.cron_start_date).format(
                      //           "MMM D, YYYY"
                      //         )}
                      //       </div>
                      //     );
                      //   },
                      // },
                      // {
                      //   field: "cron_end_date",
                      //   headerName: "Cron End Date",
                      //   headerAlign: "center",
                      //   align: "center",
                      //   minWidth: 180,
                      //   flex: 1,
                      //   sortable: false,
                      //   disbableColumnMenu: true,
                      //   renderCell: (params) => {
                      //     return (
                      //       <div>
                      //         {dayjs(params.row.cron_end_date).format(
                      //           "MMM D, YYYY"
                      //         )}
                      //       </div>
                      //     );
                      //   },
                      // },
                    ]}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                      },
                    }}
                    pageSizeOptions={[15, 50]}
                    checkboxSelection={false}
                    rowSelection={false}
                    autoHeight
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <AddEditSchedule
          open={open}
          handleClose={() => handleClose()}
          schedule_id={decryptedId}
          isAdd={false}
          fetchScheduleData={() => fetchSchedule()}
        />
      )}

      <DeleteModal
        open={DeleteSchedule}
        HandleClose={() => handleClose()}
        HandleDelete={() => HandleDeleteSchedule()}
      />
    </>
  );
};

export default SopsView;
