import React, { useEffect, useState } from "react";
import DefaultImg from "../../assets/img/DefaultImg.jpg";
import { BsArrowLeft } from "react-icons/bs";
import { TextField } from "@mui/material";
import { useAuth } from "../../context/AuthProvider";
import { HttpService } from "../../service/HttpService";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user?.id;
  const [disable, setDisable] = useState(true);
  const [oldData, setOldData] = useState([]);

  useEffect(() => {
    fetchEmployee();
  }, [user_id, showLoginPopup]);

  const fetchEmployee = () => {
    setLoading(true);
    httpService
      .get(`employee/get_employee/${user_id}`)
      .then((response) => {
        if (response) {
          setOldData(response?.data[0]);
          formik?.setFieldValue("name", response?.data[0]?.name);
          formik?.setFieldValue("email", response?.data[0]?.email);
          formik?.setFieldValue("phone", response?.data[0]?.phone);
          formik?.setFieldValue("date_of_joining", response?.data[0]?.date_of_joining);
          formik?.setFieldValue("role_name", response?.data[0]?.role_name);
          formik?.setFieldValue("start_time", response?.data[0]?.start_time);
          formik?.setFieldValue("end_time", response?.data[0]?.end_time);
          formik?.setFieldValue(
            "activities",
            (response?.data[0]?.activities?.length > 0 &&
              response?.data[0]?.activities.map((item) => item.name)) ||
              []
          );
          formik?.setFieldValue(
            "sunday_working_status",
            response?.data[0]?.sunday_working_status
          );
          formik?.setFieldValue(
            "monday_working_status",
            response?.data[0]?.monday_working_status
          );
          formik?.setFieldValue(
            "tuesday_working_status",
            response?.data[0]?.tuesday_working_status
          );
          formik?.setFieldValue(
            "wednesday_working_status",
            response?.data[0]?.wednesday_working_status
          );
          formik?.setFieldValue(
            "thursday_working_status",
            response?.data[0]?.thursday_working_status
          );
          formik?.setFieldValue(
            "friday_working_status",
            response?.data[0]?.friday_working_status
          );
          formik?.setFieldValue(
            "saturday_working_status",
            response?.data[0]?.saturday_working_status
          );
          formik?.setFieldValue(
            "company_name",
            response?.data[0]?.company_name
          );
          formik?.setFieldValue(
            "department_name",
            response?.data[0]?.department_name
          );
          formik?.setFieldValue(
            "profile_photo",
            response?.data[0]?.profile_photo
          );
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      date_of_joining: "",
      role_name: "",
      department_name: "",
      company_name: "",
      start_time: "",
      end_time: "",
      sunday_working_status: "",
      monday_working_status: "",
      tuesday_working_status: "",
      wednesday_working_status: "",
      thursday_working_status: "",
      friday_working_status: "",
      saturday_working_status: "",
      profile_photo: "",
      milestones: [],
      activities: [],
      tasks: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      phone: Yup.string()
        .required("Please Enter Mobile Number")
        .min(10)
        .max(10)
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Mobile number is not valid"
        ),
      date_of_joining: Yup.date().required("Please Enter Birthday"),
    }),
    onSubmit: async (values) => {
      const getChangedValues = (initialValues, currentValues) => {
        const changedValues = {};

        const isEqual = (a, b) => {
          if (Array.isArray(a) && Array.isArray(b)) {
            return JSON.stringify(a) === JSON.stringify(b);
          }
          return a === b;
        };

        Object.keys(currentValues).forEach((key) => {
          if (!isEqual(currentValues[key], initialValues[key])) {
            changedValues[key] = currentValues[key];
          }
        });

        return changedValues;
      };
      const changedValues = getChangedValues(oldData, values);
      try {
        const res = await httpService.put(
          `${"profile/updateProfile"}/${user_id}`,
          changedValues
        );
        if (res) {
          toast.success("Profile Updated Successfully", {
            position: "top-right",
          });
          setLoading(false);
          setDisable(true);
          fetchEmployee();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="employee-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              {/* <span className="back">
              <BsArrowLeft onClick={handleNavigate} />
            </span> */}
              Employee Profile
            </h1>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <div className="row">
            <div className="col-md-2 mb-4 mb-md-0">
              <div className="d-flex align-items-center justify-content-center h-100">
                <img
                  src={
                    formik?.values?.profile_photo
                      ? formik?.values?.profile_photo
                      : DefaultImg
                  }
                  className="img-fluid w-75"
                  alt="employee profile image"
                />
              </div>
            </div>

            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Your Name"
                      disabled={disable === true ? true : false}
                      variant="outlined"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.name}
                    />
                  </div>
                </div>

                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Email<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Your Email"
                      variant="outlined"
                      disabled
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.email}
                    />
                  </div>
                </div>

                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Phone No.<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Your Phone No."
                      variant="outlined"
                      disabled={disable === true ? true : false}
                      name="phone"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.phone}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-md-4">
                <div className="col-md-4">
                  <div className="form_field_wrapper">
                    <label>
                      Date of Joinning
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        name="date_of_joining"
                        format="MM/DD/YYYY"
                        disabled={disable === true ? true : false}
                        value={dayjs(formik?.values.date_of_joining)}
                        disableFuture
                        onChange={(date) =>
                          formik?.setFieldValue(
                            "date_of_joining",
                            dayjs(date.$d).format("MM/DD/YYYY")
                          )
                        }
                        onBlur={formik?.handleBlur}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Role<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Role"
                      variant="outlined"
                      disabled
                      name="role_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.role_name}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Department<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Department Name"
                      variant="outlined"
                      disabled
                      name="department_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.department_name}
                    />
                  </div>
                </div>
              </div>

              <div className="row mt-md-4">
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Company<span style={{ color: "red" }}>*</span>
                    </label>
                    <TextField
                      id="input-companyname"
                      InputProps={{}}
                      placeholder="Enter Company"
                      variant="outlined"
                      disabled
                      name="company_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.company_name}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      Start Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        name="start_time"
                        disabled
                        value={dayjs(formik?.values.start_time, "HH:mm:ss")}
                        onBlur={formik?.handleBlur}
                        onChange={(date) =>
                          formik?.setFieldValue(
                            "start_time",
                            dayjs(date.$d).format("HH:mm:ss")
                          )
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="form_field_wrapper">
                    <label>
                      End Time<span style={{ color: "red" }}>*</span>
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        name="end_time"
                        disabled
                        value={dayjs(formik?.values.end_time, "HH:mm:ss")}
                        onBlur={formik?.handleBlur}
                        onChange={(date) =>
                          formik?.setFieldValue(
                            "end_time",
                            dayjs(date.$d).format("HH:mm:ss")
                          )
                        }
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-4">
              <div className="col-md-10">
                <div className="d-flex justify-content-end gap-3">
                  <button
                    onClick={() => {
                      setDisable(false);
                    }}
                    className="btn btn-border"
                    type="button"
                  >
                    Edit
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
