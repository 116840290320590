import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { PiUploadSimpleThin } from "react-icons/pi";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { RxCross1 } from "react-icons/rx";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { HttpService } from "../../../service/HttpService";
import { useNavigate } from "react-router-dom";

function EditRole({ open, clickedRole, fetchRole, handleClose }) {
  //   console.log(clickedRole);
  const navigate = useNavigate();
  const httpService = new HttpService();
  useEffect(() => {
    // console.log("useeffect running");
    if (clickedRole !== "") {
      httpService.get(`role/get_role/${clickedRole}`).then((response) => {
        formik.setValues({
          name: response?.data?.name,
          description: response?.data?.description,
        });
      });
    } else {
      formik.resetForm();
    }
  }, [clickedRole]);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Please Enter Role Name")
        .max(200, "Role Name must be less than 200 characters"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await httpService.put(
          `${"role/edit_role"}/${clickedRole}`,
          values
        );
        if (res) {
          toast.success("Role Updated Successfully", {
            position: "top-right",
          });
          // formik.resetForm();
          handleClose();
          fetchRole();
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleCloseSave = () => {
    handleClose();
    formik.setFieldValue("name", "");
    formik.setFieldValue("description", "");
  };

  return (
    <Modal
      className="plant-modal"
      open={open}
      onClose={() => {
        handleCloseSave();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="modal_block">
          <div className="modal_header">
            <div className="modal_title_wrapper">
              <h2 className="modal_title">Edit Role</h2>
            </div>
            <div className="cancel-btn-wrap">
              <RxCross1
                onClick={() => {
                  handleCloseSave();
                }}
              />
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label>
                    Role Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="name"
                    InputProps={{}}
                    placeholder="Enter Your Role Name"
                    variant="outlined"
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal_content">
            <div className="row">
              <div className="col-md-12">
                <div className="form_field_wrapper">
                  <label>Description</label>
                  <TextareaAutosize
                    id="description"
                    className="w-100 text-area"
                    minRows={3}
                    InputProps={{}}
                    placeholder="Enter Description"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <button
              onClick={() => {
                handleCloseSave();
              }}
              className="btn btn-border"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default EditRole;
