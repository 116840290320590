import React, { useEffect, useState } from "react";
import "./Report.scss";
import { DataGrid } from "@mui/x-data-grid";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Box, gridClasses, styled } from "@mui/material";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { HttpService } from "../../service/HttpService";
import { useAuth } from "../../context/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Decryption } from "../../Mixin/EncryptDecryptId";
import { BsArrowLeft } from "react-icons/bs";
import dayjs from "dayjs";
import DownloadButton from "../../components/DownloadButton";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { SiMicrosoftexcel } from "react-icons/si";

const Report = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [graph, setGraph] = useState([]);
  const [schedule, setSchedules] = useState();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const params = useParams();
  const { id } = params || {};
  const decryptedId = Decryption(id);
  const navigate = useNavigate();

  const fetchScheduleReport = async () => {
    setLoading(true);
    try {
      const response = await httpService.get(`schedule/reports/${decryptedId}`);
      if (response?.data) {
        const tableData =
          response?.data?.tableData &&
          response?.data?.tableData?.data &&
          response?.data?.tableData?.data[0];
        const graphData = response?.data?.graphData?.data;
        setRows(tableData?.tasks || []); // Set rows data, default to an empty array if undefined
        setGraph(graphData || []); // Set graph data, default to an empty array if undefined
        setSchedules(tableData || {}); // Set schedules, default to an empty object if undefined

        setLoading(false); // Set loading to false immediately after data is set
        return tableData;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (decryptedId) {
      fetchScheduleReport();
    }
  }, [decryptedId]);

  const handleBackClick = () => {
    navigate("/reports");
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const downloadExcel = async () => {
    try {
      const rowData = await fetchScheduleReport();
      // Create a new workbook
      const workbook = new ExcelJS.Workbook();

      // Add a worksheet
      const worksheet = workbook.addWorksheet("Schedule");

      // Add headers
      worksheet.addRow(["Schedule Name", "Start Date", "End Date"]);

      // Add data
      const selectedData = [
        [
          rowData?.schedule_name,
          rowData?.start_date
            ? dayjs(rowData?.start_date).format("MMM/DD/YYYY")
            : "",
          rowData?.end_date
            ? dayjs(rowData?.end_date).format("MMM/DD/YYYY")
            : "",
        ],
      ];

      selectedData.forEach((row) => {
        worksheet.addRow(row);
      });

      // Add a centered heading "Schedule"
      worksheet.mergeCells("A5:F5"); // Merge cells from A5 to F5
      const scheduleHeadingRow = worksheet.getRow(5); // Get the merged cell
      scheduleHeadingRow.getCell(1).value = "Schedule"; // Set the value
      scheduleHeadingRow.getCell(1).alignment = { horizontal: "center" }; // Center align the text

      // Add an empty row
      worksheet.addRow([]);

      // Add subheadings for Schedules
      worksheet.addRow([
        "Task",
        "Planned Duration",
        "Actual Duration",
        "Employees",
        "Planned Start Datetime",
        "Planned End DateTime",
        "Actual Start Datetime",
        "Actual End Datetime",
        "Plant",
        "Room",
        "Type",
        "Company",
        "Location",
      ]);

      // Add data for each schedule
      if (
        rowData?.tasks !== null &&
        rowData?.tasks !== undefined &&
        rowData?.tasks.length > 0
      ) {
        rowData?.tasks.forEach((schedule) => {
          worksheet.addRow([
            schedule?.task_name || "",
            schedule?.task_expected_duration || "",
            schedule?.task_actual_duration.trim() === "days"
              ? ""
              : schedule?.task_actual_duration,
            schedule?.employees?.employee_names?.length > 0
              ? schedule?.employees?.employee_names.join(", ")
              : "",
            schedule?.start_datetime
              ? dayjs(schedule?.start_datetime).format("MMM/DD/YYYY hh:mm a")
              : "",
            schedule?.end_datetime
              ? dayjs(schedule?.end_datetime).format("MMM/DD/YYYY hh:mm a")
              : "",
            schedule?.actual_start_datetime
              ? dayjs(schedule?.actual_start_datetime).format(
                  "MMM/DD/YYYY h:mm A"
                )
              : "",
            schedule?.actual_end_datetime
              ? dayjs(schedule?.actual_end_datetime).format(
                  "MMM/DD/YYYY h:mm A"
                )
              : "",
            schedule.plant_name,
            schedule.room_name,
            schedule.type,
            schedule.company_name,
            schedule.location_name,
          ]);
        });
      }

      // Generate Excel file
      const buffer = await workbook.xlsx.writeBuffer();

      // Save the Excel file
      saveAs(new Blob([buffer]), `schedule_${rowData?.schedule_name}.xlsx`);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { task_name, task_expected_duration, task_actual_duration } =
        payload[0].payload;

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`Tasks : ${task_name}`}</p>
          <p
            style={{ color: "#8884d8" }}
          >{`Estimated Duration : ${task_expected_duration}`}</p>
          <p style={{ color: "rgb(105 224 190)" }}>{`Actual Duration : ${
            task_actual_duration?.trim() === "days"
              ? "0 days"
              : task_actual_duration
          }`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {loading && <FullScreenLoader />}
      <div className="card">
        <div className="card-body">
          <div className="page-title">
            <div className="title-wrap">
              <h1>
                <span className="back">
                  <BsArrowLeft onClick={() => handleBackClick()} />
                </span>
                Schedule - [{schedule?.schedule_name}]
              </h1>
            </div>

            {/* <div className="right_side">
              <div className="form_field_wrapper fiter_range">
                <select
                  name="report"
                  id="report"
                  // onChange={(e) => handleChangeTask(e?.target?.value)}
                >
                  <option value="Today">Today</option>
                  <option value="This_Week">This Week</option>
                  <option value="Date_Range">Date Range</option>
                </select>
              </div>
            </div> */}
          </div>
          <div className="report-chart" style={{ width: "100%", height: 500 }}>
            <ResponsiveContainer>
              <LineChart
                width={500}
                height={300}
                data={graph}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="task_name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  style={{ top: "12px" }}
                  colorInterpolation={"#666666"}
                />
                <Line
                  type="monotone"
                  dataKey="task_expected_days"
                  name="Estimated Duration"
                  stroke="#8884d8"
                />
                <Line
                  type="monotone"
                  dataKey="task_actual_days"
                  name="Actual Duration"
                  stroke="#00A272"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body mb-3">
          <div className="page-title">
            <div className="title-wrap">
              <h1>Tasks</h1>
            </div>
            <div className="right_side">
              <Button
                variant="contained"
                color="primary"
                startIcon={<SiMicrosoftexcel />}
                className="me-3"
                onClick={() => downloadExcel()}
              >
                Download
              </Button>
            </div>
          </div>
          <div
            style={{
              height: `${rows?.length > 0 ? "" : "200px"}`,
              width: "100%",
            }}
          >
            <DataGrid
              className="department-table"
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rows={
                rows?.length > 0 &&
                rows?.map((rows, index) => ({
                  ...rows,
                  id: index + 1,
                  sequenceNumber: index + 1,
                }))
              }
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 0.5,
                },
              }}
              columns={[
                {
                  field: "task_name",
                  headerName: "Task",
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 250,
                },
                {
                  field: "task_expected_duration",
                  headerName: "Planned Duration",
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 350,
                  sortable: true,
                  renderCell: (params) => {
                    const { task_expected_duration } = params.row;
                    return (
                      <div className="employee-name-weap">
                        <span>
                          {task_expected_duration === "days"
                            ? "0 days"
                            : task_expected_duration}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  field: "task_actual_duration",
                  headerName: "Actual Duration",
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 350,
                  sortable: true,
                  renderCell: (params) => {
                    const { task_actual_duration } = params.row;
                    return (
                      <div className="employee-name-weap">
                        <span>
                          {task_actual_duration?.trim() === "days"
                            ? "0 days"
                            : task_actual_duration}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  field: "employees",
                  headerName: "Employees",
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 350,
                  sortable: true,
                  renderCell: (params) => {
                    const { employees } = params.row;
                    return (
                      <div className="employee-name-weap">
                        <span>
                          {employees?.employee_names?.length &&
                            employees?.employee_names.join(", ")}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  field: "start_datetime",
                  headerName: "Planned Start Datetime",
                  headerAlign: "center",
                  align: "center",
                  minWidth: 250,
                  flex: 1,
                  sortable: false,
                  disbableColumnMenu: true,
                  renderCell: (params) => {
                    return (
                      <div>
                        {dayjs(params.row.start_datetime).format(
                          "MMM/DD/YYYY h:mm A"
                        )}
                      </div>
                    );
                  },
                },
                {
                  field: "end_datetime",
                  headerName: "Planned End Datetime",
                  headerAlign: "center",
                  align: "center",
                  minWidth: 250,
                  flex: 1,
                  sortable: false,
                  disbableColumnMenu: true,
                  renderCell: (params) => {
                    return (
                      <div>
                        {dayjs(params.row.end_datetime).format(
                          "MMM/DD/YYYY h:mm A"
                        )}
                      </div>
                    );
                  },
                },
                {
                  field: "actual_start_datetime",
                  headerName: "Actual Start Datetime",
                  headerAlign: "center",
                  align: "center",
                  minWidth: 250,
                  flex: 1,
                  sortable: false,
                  disbableColumnMenu: true,
                  renderCell: (params) => {
                    return (
                      <div>
                        {params.row.actual_start_datetime
                          ? dayjs(params.row.actual_start_datetime).format(
                              "MMM/DD/YYYY h:mm A"
                            )
                          : "--"}
                      </div>
                    );
                  },
                },
                {
                  field: "actual_end_datetime",
                  headerName: "Actual End Datetime",
                  headerAlign: "center",
                  align: "center",
                  minWidth: 250,
                  flex: 1,
                  sortable: false,
                  disbableColumnMenu: true,
                  renderCell: (params) => {
                    return (
                      <div>
                        {params.row.actual_end_datetime
                          ? dayjs(params.row.actual_end_datetime).format(
                              "MMM/DD/YYYY h:mm A"
                            )
                          : "--"}
                      </div>
                    );
                  },
                },
                {
                  field: "plant_name",
                  headerName: "Plant",
                  minWidth: 150,
                  flex: 1,
                },
                {
                  field: "room_name",
                  headerName: "Room",
                  minWidth: 150,
                  flex: 1,
                },
                {
                  field: "type",
                  headerName: "Type",
                  minWidth: 100,
                  flex: 1,
                },
                {
                  field: "company_name",
                  headerName: "Company",
                  flex: 1,
                  minWidth: 200,
                  maxWidth: 350,
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    const { company_name } = params.row;
                    return (
                      <div className="employee-name-weap">
                        <span>{company_name || "--"}</span>
                      </div>
                    );
                  },
                },
                {
                  field: "location_name",
                  headerName: "Location",
                  flex: 1,
                  minWidth: 100,
                  cellClassName:'location_col',
                  sortable: false,
                  disableColumnMenu: true,
                  renderCell: (params) => {
                    const { location_name } = params.row;
                    return (
                      <div className="employee-name-weap">
                        <span>{location_name || "--"}</span>
                      </div>
                    );
                  },
                },
              ]}
              checkboxSelection={false}
              rowSelection={false}
              autoHeight
              {...other}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
